import { Box, BoxProps, Button, Select } from '@ytl/common-web';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../contexts/languageContext';
import { getConfig } from '../../data/config';

export const useMfaSetupView = (onContinue: (mode: 'sms' | 'authenticator') => void) => {
  const { translation } = useLanguage();
  const navigate = useNavigate();
  const [mfaSetupType, setMfaSetupType] = useState<{ value: string; label: string }>({
    value: 'sms',
    label: translation.accountSettings.mfa.setup.mfaTypeSMS,
  });

  const handleEdit = useCallback((e: KeyboardEvent) => {
    if (!['Escape', 'ArrowUp', 'ArrowDown', 'Tab', 'ShiftTab', 'Enter'].includes(e.code)) {
      e.preventDefault();
    }
  }, []);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      // ...errorIcon,
      header: translation.accountSettings.mfa.setup.title,
      subHeader: (
        <>
          {translation.accountSettings.mfa.setup.content}
          <br />
          <Link
            className="setup__link"
            to={getConfig().mfaIWantToLearnMoreLink}
          >
            {translation.accountSettings.mfa.setup.moreLink}
          </Link>
        </>
      ),
      action: (
        <Button onClick={() => onContinue(mfaSetupType.value === 'sms' ? 'sms' : 'authenticator')}>
          {translation.accountSettings.mfa.setup.continue}
        </Button>
      ),
      subAction: (
        <Button
          variant="text-undecorated"
          onClick={() => navigate(-1)}
        >
          {translation.accountSettings.mfa.setup.cancel}
        </Button>
      ),
    };
  }, [translation, navigate, mfaSetupType, onContinue]);

  const view = useMemo(
    () => (
      <Box {...boxConfig}>
        <Select
          options={[
            { value: 'sms', label: translation.accountSettings.mfa.setup.mfaTypeSMS },
            { value: 'authenticator', label: translation.accountSettings.mfa.setup.mfaTypeAuthenticator },
          ]}
          label={
            <>
              {translation.accountSettings.mfa.setup.mfaTypeLabel}
              {/*<Tooltip*/}
              {/*  html={translation.accountSettings.mfa.setup.mfaTypeLabelTooltip}*/}
              {/*  icon={<InfoIcon className={'icon icon--tooltip'} />}*/}
              {/*  includeIconInTooltip={true}*/}
              {/*/>*/}
            </>
          }
          name="type"
          onChange={e => {
            setMfaSetupType(e);
          }}
          value={mfaSetupType}
          isLoading={false}
          onKeyDown={handleEdit}
          placeholder={''}
        />
      </Box>
    ),
    [boxConfig, mfaSetupType, setMfaSetupType, translation, handleEdit],
  );

  return {
    view,
  };
};
