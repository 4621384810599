export const translationHu = {
  header: {
    menu: {
      backToYettel: 'Vissza a Yettel.hu-ra',
    },
  },
  errorPage: {
    header: `Hoppá!<br />Váratlan hiba történt`,
    subHeader: 'Próbáld újra később, ha továbbra sem sikerül, vedd fel velünk a kapcsolatot chat-en.', //ez link. Dorka TODO: chat link
  },
  notConfirmedEmail: {
    header: 'Hoppá!<br />Úgy látjuk neked már küldtünk megerősítő e-mailt.',
    subHeader:
      'Úgy látjuk kaptál tőlünk megerősítő e-mailt az elmúlt 24 órában. Kérlek nézd meg az e-mail fiókodat és aktiváld regisztrációd.',
    action: 'Vissza a yettel.hu-ra',
  },
  initialPasswordSet: {
    header: 'Fiók létrehozása',
    subHeader: 'Add meg az új fiókodhoz tartozó jelszót',
    subHeaderDuringLoading: 'Kérlek várj, amíg ellenőrizzük a fiókod.',
    createAccount: 'Létrehozás',
    success: {
      header: 'A fiókodat sikeresen létrehoztuk',
      subHeader: 'Tovább a belépéshez',
    },
    error: {
      header: 'Hoppá!<br/>Váratlan hiba történt',
      subHeader: 'A fiókot nem sikerült létrehozni. Kérjük próbáld újra később.',
    },
    missingSession: {
      header: 'Hoppá!<br/>Valami hiba történt',
      subHeader: 'A fiókot nem lehet létrehozni.',
    },
    invalidSession: {
      header: 'Hoppá!<br/>Érvénytelen azonosító',
      subHeader: 'A fiókot nem lehet létrehozni.',
    },
    continueToAccount: 'Tovább a Fiókomhoz',
    tryAgain: 'Újra próbálom',
  },
  changePassword: {
    header: 'Új jelszó beállítása',
    subHeader: (email: string) => `Add meg új jelszavad <u>${email}</u> e-mail címhez tartozó Yettel fiókodhoz.`,
    saveButton: 'Mentés',
    success: {
      header: 'Jelszó beállítása sikeres',
      subHeader: (email: string) =>
        `Sikeresen beállítottad új jelszavad ${email} e-mail címhez tartozó Yettel fiókodhoz.`,
      backToWeb: 'Vissza a webre',
      backToApp: 'Vissza az appba',
    },
    error: {
      header: 'Hoppá!<br/>Váratlan hiba történt',
      subHeader: (email: string) =>
        `Nem sikerült beállítani az új jelszavad ${email} e-mail címhez. Kérjük próbáld újra később.`,
    },
    continueToAccount: 'Belépés',
    continueToHome: 'Folytatás',
    tryAgain: 'Újra próbálom',
    invalidSession: {
      header: 'Érvénytelen link!',
      subHeader: 'Nincs ilyen, vagy lejárt megerősítő link.',
    },
  },
  emailConfirm: {
    header: 'E-mail cím visszaigazolása',
    subHeader: 'Kérlek várj amíg visszaigazoljuk az email címed.',
    success: {
      header: `Email cím visszaigazolása sikeres`,
      subHeader: 'Sikeresen visszaigazoltuk az e-mail címed.',
      continueToHome: 'Folytatás',
    },
    error: {
      header: `Hoppá!<br />Váratlan hiba történt`,
      subHeader: 'Nem sikerült visszaigazolni az e-mail címed. Kérjük próbáld újra később.',
      continueToYettel: 'Vissza a yettel.hu-ra',
    },
    missingSession: {
      header: 'Hoppá!<br />A megerősítő e-mail lejárt kérj újat',
      subHeader: 'Az e-mail címet nem lehet visszaigazolni. Kérj egy másik linket, hogy megerősíthes a fiókod.',
    },
  },
  homePage: {
    header: 'Fiók beállítások',
    tabs: {
      home: 'Fiók főoldal',
      settings: 'Fiók beállítások',
    },
  },
  accountSettings: {
    accountDataCard: {
      header: 'Fiók adatok',
      nameLabel: 'Fiókhoz tartozó név',
      nameFormat: 'Érvénytelen név',
      emailLabel: 'E-mail cím',
      emailLabelAgain: 'E-mail cím újra',
      registrationType: 'Regisztráció típusa',
      registrationTypeEmail: 'E-mail címmel',
      modifyButton: 'Módosítás',
      saveButton: 'Mentés',
      cancelButton: 'Elvetés',
      errors: {
        emptyEmail: 'Az e-mail cím nem lehet üres.',
        invalidEmail: 'Érvényes e-mail címet kell megadnod.',
        emptyName: 'A név nem lehet üres.',
      },
    },
    passwordDataCard: {
      header: 'Jelszó',
      passwordLabel: 'Fiók jelszó',
      passwordValue: '••••••••',
      modifyButton: 'Módosítás',
    },
    oauth: {
      header: 'Biztonság',
      connect: {
        google: 'Belépek Google fiókkal',
        facebook: 'Belépek Facebook fiókkal',
        apple: 'Belépek Apple fiókkal',
      },
      disconnect: {
        google: 'Google fiók leválasztása',
        facebook: 'Facebook fiók leválasztása',
        apple: 'Apple fiók leválasztása',
      },
    },
    deleteAccountCard: {
      header: 'Fiók törlése',
      deleteButton: 'Törlöm a Yettel fiókom',
    },
    mfa: {
      setupAction: 'Többlépcsős azonosítás társítása',
      disconnectAction: 'Többlépcsős azonosítás leválasztása',
      list: {
        header: 'Autentikátorhoz kötött jelszavad',
        name: 'Neve',
        time: 'Időpont',
        generateNewCode: 'Új kódot generálok',
      },
      setup: {
        title: 'Többlépcsős azonosítás társítása',
        content: 'Védd meg fiókodat többlépcsős azonosítással!',
        moreLink: 'Többet szeretnék tudni',
        mfaTypeLabel: 'Válaszd ki az azonosítás másik lépcsőjét',
        mfaTypeSMS: 'SMS-ben kérem a kódot',
        mfaTypeAuthenticator: 'Autentikátort használok',
        continue: 'Tovább',
        cancel: 'vissza',
      },
      smsSetup: {
        title: 'Mobilszám megadása az ellenőrzéshez',
        content: 'Add meg a mobilszámot, ahova az ellenőrző kódot küldjük.',
        failedToSend: 'A megadott telefonszám nem tartozik az előfizetésedhez.',
        phoneNumberLabel: 'Másodlagos telefonszám',
        phoneNumberLabelTooltip: 'Nem kell beírnod a szóközöket vagy a kötőjeleket.',
        continue: 'Jóváhagyom és tovább',
        cancel: 'vissza',
      },
      smsConfirm: {
        title: 'Igazold telefonszámod az ellenőrző kóddal',
        content: (phoneNumber: string) =>
          `Az ellenőrző kódot elküldtük SMS-ben a ${phoneNumber} végű telefonszámodra.<strong>Ellenőrizd az üzeneteidet, majd add meg a kódot.</strong>`,
        verificationCodeLabel: 'ellenőrző kód',
        verificationCodeHint: 'Nem kaptad meg SMS-ben a kódot?',
        continue: 'Belépek',
        cancel: 'Add meg az ellenőrző kódot telefonhívással.',
        errorInvalidCode: 'Érvénytelen kód',
        error: 'Nem sikerült elküldeni az ellenőrző kódot. Kérjük próbáld újra később.',
      },
      smsSuccess: {
        title: 'Sikeresen beállítottad a Többlépcsős azonosítást',
        content: (email: string) =>
          `Sikeresen beállítottad a többlépcsős azonosítást az ${email} címhez tartozó Yettel fiókodhoz.`,
        continue: 'Rendben',
      },
      authenticatorSetup: {
        title: 'Add meg az eszközt, amin az autentikátort használni fogod',
        content:
          'Generálunk egy kódot, amit meg kell adnod az Authenticator alkalmazás társításához kivéve ha QR-kódal szeretnél autentikálni.',
        nameLabel: 'Eszköz neve',
        nameLabelTooltip: 'Olyan nevet válassz, ami nem tartalmaz speciális karaktert és mindig tudod melyik fiókodat jelöli.',
        continue: 'Társító kód generálása',
        cancel: 'vissza',
        error: `Nem sikerült előállítani a társító kódot. Kérjük próbáld újra később.`,
      },
      authenticatorCodeGen: {
        title: 'Jelszótársító kód',
        content:
          'Ezt a kódot másold be az Authenticator alkalmazás megfelelő mezőjébe vagy olvasd be a QR kódot, hogy létrehozd a második lépcsőt.',
        copy: 'Másolás',
        continue: 'Tovább az ellenőrzéshez',
      },
      authenticatorSuccess: {
        title: 'Sikeresen beállítottad a Többlépcsős azonosítást',
        content: (email: string) =>
          `Sikeresen beállítottad a Többlépcsős azonosítást <strong> ${email} </strong> e-mail címhez tartozó yettel fiókodhoz.`,
        continue: 'Rendben',
      },
    },
    smsLoginError: {
      title: `Hoppá!<br />Úgy néz ki csak SMS-el vagy bejelentkezve`,
      content: `Ezt a felületet csak emailcím+jelszó szintű bejelentkezéssel tudod kezelni. Jelentkezz be így vagy térj vissza a főoldalra`,
      primaryAction: `Belépés fiókkal`,
      secondaryAction: `Vissza a fiok.yettel.hu-ra`,
    },
  },
  dialogSet: {
    leaveDialog: {
      title: 'Biztos, hogy elhagyod az oldalt?',
      content: 'A módosítások el fognak veszni.',
      okLabel: 'Maradok',
      cancelLabel: 'Oldal elhagyása',
    },
    changeDialog: {
      title: 'Biztos, hogy elveted a módosításokat?',
      content: 'A módosítások el fognak veszni.',
      okLabel: 'Megtartás',
      cancelLabel: 'Elvetem',
    },
    successDialog: {
      title: 'Sikeres módosítás',
      subHeader: 'Sikeresen módosítottad a fiókbeállításokat.',
      okLabel: 'Rendben',
    },
    errorDialog: {
      title: 'Hoppá!<br />Váratlan hiba történt',
      content: 'Fiókbeállítások módosítása sikertelen. Kérjük próbáld újra később.',
      okLabel: 'Rendben',
    },
    emailChangeSuccessDialog: {
      title: 'Kérjük erősítsd meg új e-mail címed',
      content:
        'Az e-mail cím módosítása a megerősítést követően fejeződik be. A megerősítéshez tartozó linket elküldtük az új e-mail címedre.',
      cancelLabel: 'Vissza a Yettel fiókhoz',
    },
    deleteAccountDialog: {
      title: 'Biztos, hogy törölni szeretnéd Yettel fiókod?',
      content:
        'Ha törlöd a fiókod, a Telenort a jövőben csak egy új regisztráció után fogod tudni használni. A fiók törlése a fiókodhoz hozzáadott előfizetéseidet nem érinti.',
      cancelLabel: 'Mégsem',
      dangerLabel: 'Fiók törlése',
      errorTitle: 'Hoppá!<br /> Váratlan hiba történt',
      errorConfirm: 'Bezárás',
      successTitle: 'Fiókod törlésre került.',
      successContent:
        'Fiókod törlésre került, nem tudsz ezután belépni a felületeinkre ezzel az e-mail cím+jelszó párossal.',
      successConfirm: 'Vissza a Yettel.hu-ra',
      cantDeleteTitle: 'Ezt a fiókot nem törölheted.',
      yoloContent: (videoChatUrl: string) =>
        `A fiókodban YettelYepp típusú előfizetés található így ezt a fiókot nem törölheted. Vedd fel velünk a kapcsolatot <a href="${videoChatUrl}" target="_blank" rel="noreferrer noopener">chat</a>-en, hogy segíthessünk.`,
      tvContent: (videoChatUrl: string) =>
        `A fiókodban Yettel TV típusú előfizetés található így ezt a fiókot nem törölheted. Vedd fel velünk a kapcsolatot <a href="${videoChatUrl}" target="_blank" rel="noreferrer noopener">chat</a>-en, hogy segíthessünk.`,
      cantDeleteConfirm: 'Vissza a fiókhoz',
    },
  },
  passwordSet: {
    password: 'Új jelszó',
    passwordAgain: 'Jelszó még egyszer',
    passwordOriginal: 'Eredeti jelszó',
    criteria: 'A jelszónak tartalmaznia kell',
    criteria1: 'Kisbetűket',
    criteria2: 'Nagybetűket',
    criteria3: 'Legalább egy számot',
    criteria4: 'Legalább egy speciális karaktert',
    error: {
      doesntMatch: 'A beírt jelszavak nem egyeznek.',
    },
  },
  forgottenPassword: {
    header: (lock?: boolean): string => (lock ? 'Jelszó módosítás' : 'Elfelejtett jelszó'),
    subHeader:
      'Kérjük add meg a Yettel fiókodhoz tartozó e-mail címed, amire elküldhetjük a jelszóváltoztatáshoz / jelszó beállításhoz tartozó teendőidet.',
    send: 'Küldés',
    emailLabel: 'E-mail cím',
    success: {
      header: 'Az e-mailt elküldtük',
      subHeader: (email: string) =>
        `A jelszóváltoztatáshoz szükséges teendőket elküldtük a(z) <strong>${email}</strong> címre. Nézd meg és kövesd az utasításokat a jelszó módosításhoz.`,
      resend: 'E-mail újra küldése',
      backToYettel: 'Vissza a belépéshez',
    },
    error: {
      header: 'Hoppá!<br />Váratlan hiba történt',
      subHeader: '',
      tryAgain: 'Újra próbálom',
      invalidEmail: 'Érvénytelen e-mail cím.',
    },
  },
  loginRegister: {
    register: {
      box: {
        header: 'Yettel fiók regisztráció',
        name: 'Név',
        email: 'E-mail cím',
        emailTooltip:
          'Ezzel az e-mail címel tudod azonosítani magad a yettel.hu rendszerében. Egy olyan email cím legyen, amihez mindíg hozzáférsz.',
        password: 'Jelszó',
        passwordAgain: 'Jelszó még egyszer',
        passwordHint:
          'A jelszónak tartalmaznia kell: kisbetűt, nagybetűt, egy számot, egy speciális karaktert, és minimum 10, maximum 24 karakter hosszú lehet',
        register: 'Regisztráció',
        back: 'Vissza',
        oauthSectionLabel: 'vagy',
        oauthGoogle: 'Regisztráció Google fiókkal',
        oauthFacebook: 'Regisztráció Facebook fiókkal',
        error: 'A fiók regisztráció sikertelen. Kérjük próbáld újra később',
        errorInvalidEmail: 'Érvénytelen e-mail cím',
        errorAccountAlreadyExists: (loginUrl: string, forgottenPasswordUrl: string) =>
          `Ehhez az e-mail címhez már van fiókod. <a href="${loginUrl}">Lépj be</a> vagy <a href="${forgottenPasswordUrl}">Kérj jelszó emlékeztetőt.</a>`,
        loginWithSMS: 'Belépés SMS kóddal',
        loginWithYolo: 'Belépek',
        passwordTooltip:
          'A jelszónak tartalmaznia kell:<br/>' +
          '- Kisbetűt<br/>' +
          '- Nagybetűt<br/>' +
          '- Legalább egy számot<br/>' +
          '- Legalább egy speciális karaktert<br/>' +
          '- Minimum 10 maximum 24 karaktert',
      },
      hero: {
        header: 'Intézd ügyeidet online Yettel fiókkal!',
        desc: (link: string) =>
          `Nincs még fiókod? Regisztrálj pár kattintással. Ha elakadtál, kérj segítséget <a href="${link}">chat</a>-en.`,
      },
      result: {
        header: 'Yettel fiók regisztráció',
        subHeader: 'Az e-mailt elküldtük',
        desc: 'A megadott e-mail címedre elküldtük az aktiváló linket tartalmazó e-mail-t. Kérjük, ellenőrizd postafiókodat és véglegesítsd regisztrációdat, erre 24 óra áll rendelkezésedre, utána újra kell kezdened a regisztrációs folyamatot.',
        resend: 'E-mail újra küldése',
        backToYettelAccount: 'Vissza a belépéshez',
      },
      registrationComplete: {
        header: 'A fiókodat sikeresen létrehoztuk',
        desc: 'Tovább a fiókhoz',
        goToLogin: 'tovább a bejelentkezéshez',
      },
      registrationFailed: {
        header: 'Hoppá!<br />Váratlan hiba történt',
        desc: 'Valami hiba történt, vedd fel velünk a kapcsolatot cheten vagy próbáld meg később.', // itt chat Dorka TODO: make it link
        tryAgain: 'Vissza a regisztrációhoz',
      },
    },
    registerWebView: {
      box: {
        header: 'Yettel fiók létrehozása',
        title: 'Hozz létre egy fiókot! Ezt az e-mail címet fogjuk kapcsolattartásra is használni a későbbiekben.',
        continue: 'Tovább',
      },
    },
    otp: {
      header: 'Aktiváló link',
      title: 'Küldtünk egy e-mail-t az aktiváló kódoddal, kérlek, add meg itt a kapott kódot:',
      sendAgain: 'Újra küldés',
      back: 'Vissza',
      invalidCode: 'A megadott aktiváló kód nem megfelelő',
    },
  },
  password: {
    passwordHint: {
      title: 'A jelszónak tartalmaznia kell:',
      lowerCase: 'Kisbetűt',
      upperCase: 'Nagybetűt',
      atLeastANumber: 'Legalább egy számot',
      atLeastASpecial: 'Legalább egy speciális karaktert',
      lengthCheck: 'Minimum 10, maximum 24 karaktert',
    },
    errors: {
      lowerCase: 'A jelszavad nem tartalmaz kisbetűt',
      upperCase: 'A jelszavad nem tartalmaz nagybetűt',
      atLeastANumber: 'A jelszavad nem tartalmaz legalább egy számot',
      atLeastASpecial: 'A jelszavad nem tartalmaz legalább egy speciális karatert',
      lengthCheck: 'Ez a jelszó nem minimum 10 karakter és nem maximum 24',
    },
  },
  emailChange: {
    header: 'E-mail cím módosítása',
    title: 'Add meg az új e-mail címedet.',
    email: 'E-mail cím',
    emailAgain: 'E-mail cím még egyszer',
    error: {
      invalidEmail: 'Érvénytelen e-mail cím',
      emailMismatch: 'Az e-mail címek nem egyeznek',
      general: 'Az e-mail cím módosítása sikertelen. Kérjük próbáld újra később.',
    },
    continue: 'Tovább',
    otpHeader: 'Küldtünk egy e-mailt az aktiváló kódoddal',
  },
  emailChangeBlock: {
    header: 'Biztosan tiltani szeretnéd az e-mail cím módosítást?',
    content1:
      'Úgy látjuk e-mail címet szeretnél módosítani. Ha nem te kezdeményezted a folyamatot, akkor tiltsd le és javasoljuk, hogy változtass jelszót.',
    block: 'E-mal cím csere tiltása',
    cancel: 'Mégsem',
    error: {
      header: 'Hoppá!<br />Váratlan hiba történt',
      subHeader: 'Az email cím csere tiltása nem sikerült. Kérjük próbáld újra később.',
      back: 'Vissza',
    },
  },
};

export const translationEn: typeof translationHu = {
  header: {
    menu: {
      backToYettel: 'Back to Yettel.hu',
    },
  },
  errorPage: {
    header: 'Ups!<br/> Something failed.',
    subHeader: 'An unexpected error happened.',
  },
  notConfirmedEmail: {
    header: 'Ups!<br /> You have already received a confirmation email',
    subHeader:
      'You need to confirm your email address to log in. We have sent a confirmation email to your Yettel account.',
    action: 'Back to yettel.hu',
  },
  initialPasswordSet: {
    header: 'Create account',
    subHeader: 'Create a password for your new account',
    subHeaderDuringLoading: 'Please wait until we check your account',
    createAccount: 'Create',
    success: {
      header: 'Your account has been created',
      subHeader: 'Check your orders',
    },
    error: {
      header: 'Ups!<br/>An unexpected error happened',
      subHeader: 'Your account has failed to be created. Try again later.',
    },
    missingSession: {
      header: 'Ups!<br/>Invalid url!',
      subHeader: 'Account cannot be created',
    },
    invalidSession: {
      header: 'Ups!<br/>Invalid account creation url!',
      subHeader: 'Account cannot be created',
    },
    continueToAccount: 'Continue to my account',
    tryAgain: 'Try again',
  },
  changePassword: {
    header: 'Change password',
    subHeader: (email: string) => `Enter your new password to your account with the email address ${email}.`,
    saveButton: 'Save',
    success: {
      header: 'Password change succeeded',
      subHeader: (email: string) =>
        `You have successfully changed the password to your account with the email address ${email}.`,
      backToWeb: 'back to the web',
      backToApp: 'back to the app',
    },
    error: {
      header: 'Ups!<br/>An unexpected error happened',
      subHeader: (email: string) => `Failed to change the password to your account with the email address ${email}.`,
    },
    continueToAccount: 'Log in',
    continueToHome: 'Continue',
    tryAgain: 'Try again',
    invalidSession: {
      header: 'Invalid password change url!',
      subHeader: 'Expired or invalid url.',
    },
  },
  emailConfirm: {
    header: 'Confirm email address',
    subHeader: 'Please wait until we confirm your email address.',
    success: {
      header: `Email address confirmed`,
      subHeader: 'We have successfully confirmed your email address.',
      continueToHome: 'Continue',
    },
    error: {
      header: 'Ups!<br />An unexpected error happened',
      subHeader: 'Failed to confirm your email address. Please try again later.',
      continueToYettel: 'Back to yettel.hu',
    },
    missingSession: {
      header: 'Ups!<br />The confirmation email has expired, please request a new one',
      subHeader: 'Email address cannot be confirmed.',
    },
  },
  homePage: {
    header: 'Account settings',
    tabs: {
      home: 'Account home',
      settings: 'Account settings',
    },
  },
  accountSettings: {
    accountDataCard: {
      header: 'Account data',
      nameLabel: 'Name',
      emailLabel: 'Email address',
      emailLabelAgain: 'Email address again',
      nameFormat: 'Invalid name',
      registrationType: 'Type of registration',
      registrationTypeEmail: 'E-mail',
      modifyButton: 'Modify',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      errors: {
        emptyEmail: 'The email address cannot be empty.',
        invalidEmail: 'You must give a valid email address.',
        emptyName: 'The name cannot be empty.',
      },
    },
    passwordDataCard: {
      header: 'Password',
      passwordLabel: 'Account password',
      passwordValue: '••••••••',
      modifyButton: 'Modify',
    },
    deleteAccountCard: {
      header: 'Delete account',
      deleteButton: 'Delete my Yettel account',
    },
    mfa: {
      setupAction: 'Setup multi-factor authentication',
      disconnectAction: 'Disconnect multi-factor authentication',
      list: {
        header: 'Your password is connected to Authenticator',
        name: 'Name',
        time: 'Time',
        generateNewCode: 'Generate new code',
      },
      setup: {
        title: 'Setup multi-factor authentication',
        content: 'Multi-factor authentication protects your account from unauthorized access.',
        moreLink: 'Learn more',
        mfaTypeLabel: 'Choose the second step of authentication',
        mfaTypeSMS: 'I want to use SMS code',
        mfaTypeAuthenticator: 'I want to use Authenticator',
        continue: 'Continue',
        cancel: 'Back',
      },
      smsSetup: {
        title: 'Enter your phone number',
        content: 'Enter a phone number you trust to receive the code.',
        phoneNumberLabel: 'Secondary phone number',
        phoneNumberLabelTooltip: 'You do not need to enter spaces or dashes',
        failedToSend: 'Failed to send the verification code. Please try again later.',
        continue: 'Approve and continue',
        cancel: 'Back',
      },
      smsConfirm: {
        title: 'Confirm your phone number by entering the verification code.',
        content: (phoneNumber: string) =>
          `We will send the verification code (SMS) to your phone number ending with ${phoneNumber}. <strong>Check your messages and enter the code.</strong>`,
        verificationCodeLabel: 'Verification code',
        verificationCodeHint: "Didn't receive the verification code?",
        continue: 'Log in',
        cancel: 'I choose another way to set up multi-factor authentication.',
        errorInvalidCode: 'Invalid code',
        error: 'Failed to send the verification code. Please try again later.',
      },
      smsSuccess: {
        title: 'You have successfully set up multi-factor authentication!',
        content: (email: string) =>
          `You have successfully set up multi-factor authentication for your yettel account with the email address ${email}.`,
        continue: 'Log in',
      },
      authenticatorSetup: {
        title: 'Enter the name of the device you will use the authenticator with',
        content: 'You will need this code to connect Authenticator.',
        nameLabel: 'Name',
        nameLabelTooltip: 'Choose a name that does not contain special characters and always know which account it refers to.',
        continue: 'Generate authenticator code',
        cancel: 'Back',
        error: `Failed to generate the verification code. Please try again later.`,
      },
      authenticatorCodeGen: {
        title: 'Password authenticator code',
        content: 'Copy this code to the appropriate field of the authenticator to create the second factor.',
        copy: 'copy',
        continue: 'Continue',
      },
      authenticatorSuccess: {
        title: 'You have successfully set up multi-factor authentication!',
        content: (email: string) =>
          `You have successfully set up multi-factor authentication for your yettel account with the email address ${email}.`,
        continue: 'Log in',
      },
    },
    oauth: {
      header: 'Security',
      connect: {
        google: 'Login with Google',
        facebook: 'Login with Facebook',
        apple: 'Login with Apple',
      },
      disconnect: {
        google: 'Remove Google login',
        facebook: 'Remove Facebook login',
        apple: 'Remove Apple login',
      },
    },
    smsLoginError: {
      title: `Ups!<br />It looks like you can only log in with SMS or logged in`,
      content: `You can only manage this interface with an email address + password level login. Log in this way or return to the home page`,
      primaryAction: `Log in with account`,
      secondaryAction: `Back to fiok.yettel.hu`,
    },
  },
  dialogSet: {
    leaveDialog: {
      title: 'Are you sure you want to leave this page',
      content: 'All changes will be lost',
      okLabel: 'Stay',
      cancelLabel: 'Leave without saving',
    },
    changeDialog: {
      title: 'Are you sure you want to discard changes?',
      content: 'All changes will be lost',
      okLabel: 'Megtartás',
      cancelLabel: 'Elvetem',
    },
    successDialog: {
      title: 'Success',
      subHeader: 'You have successfully modified your account.',
      okLabel: 'Ok',
    },
    errorDialog: {
      title: 'Oops!<br />Something went wrong',
      content: 'An error occured while trying to update your account information. Please try again later.',
      okLabel: 'Ok',
    },
    emailChangeSuccessDialog: {
      title: 'Please validate your new email address',
      content:
        'The change of email address will take effect after validation. We have sent a validation email to your new address.',
      cancelLabel: 'Back to my Yettel account',
    },
    deleteAccountDialog: {
      title: 'Are you sure you want to delete your account?',
      content:
        'If you delete your account, you may only use MyTelenor after registering a new account. Deleting your account does not affect your current subscriptions.',
      cancelLabel: 'Cancel',
      dangerLabel: 'Delete account',
      errorTitle: 'Oops!<br />An unexpected error happened',
      errorConfirm: 'Close',
      successTitle: 'Your account has been deleted.',
      successContent: 'Your account has been deleted, you will not be able to log in with this email password pair.',
      successConfirm: 'back to yettel.hu',
      cantDeleteTitle: 'You cannot delete this account.',
      yoloContent: (videoChatUrl: string) =>
        `This account has a YettelYepp type subscription, so you cannot delete this account. Contact us through <a href="${videoChatUrl}" target="_blank" rel="noreferrer noopener">video chat</a> to help.`,
      tvContent: (videoChatUrl: string) =>
        `This account has a Yettel TV type subscription, so you cannot delete this account. Contact us through <a href="${videoChatUrl}" target="_blank" rel="noreferrer noopener">video chat</a> to help.`,
      cantDeleteConfirm: 'Try again',
    },
  },
  passwordSet: {
    password: 'New password',
    passwordAgain: 'New password again',
    passwordOriginal: 'Current password',
    criteria: 'The password must contain',
    criteria1: 'Lower case letters',
    criteria2: 'Upper case letters',
    criteria3: 'At least a number',
    criteria4: 'At least a special character',
    error: {
      doesntMatch: "The passwords doesn't match.",
    },
  },
  forgottenPassword: {
    header: (lock?: boolean): string => (lock ? 'Change password' : 'Change password'),
    subHeader:
      'Enter the email address of your account where we can send information how to change or set your password.',
    send: 'Send',
    emailLabel: 'Email address',
    success: {
      header: 'Email sent',
      subHeader: (email: string) => `We have sent an email how to change your password to the ${email} email address.`,
      resend: 'Send again',
      backToYettel: 'Back to Yettel',
    },
    error: {
      header: 'Ups!<br />An unexpected error happened',
      subHeader: '',
      tryAgain: 'Try again',
      invalidEmail: 'Invalid email address.',
    },
  },
  loginRegister: {
    register: {
      box: {
        header: 'Yettel account registration',
        name: 'What is your name?',
        email: 'Enter your email address',
        emailTooltip:
          'You can identify yourself in the yettel.hu system with this email address. It should be an email address you have access to.',
        register: 'Register',
        back: 'Back',
        password: 'Password',
        passwordAgain: 'Password again',
        passwordHint:
          'The password must contain: lowercase letter, uppercase letter, a number, a special character, and must be at least 10, maximum 24 characters long',
        oauthSectionLabel: 'or',
        oauthGoogle: 'Register with Google account',
        oauthFacebook: 'Register with Facebook account',
        error: 'Failed to create account. Please try again later',
        errorInvalidEmail: 'Invalid email address',
        errorAccountAlreadyExists: (loginUrl: string, forgottenPasswordUrl: string) =>
          `You already have an account with this email address. <a href="${loginUrl}">Log in</a> or <a href="${forgottenPasswordUrl}">Request a password reminder.</a>`,
        loginWithSMS: 'Login with SMS',
        loginWithYolo: 'Login',
        passwordTooltip:
          'The password must contain:<br/>' +
          '- Lower case letters<br/>' +
          '- Upper case letters<br/>' +
          '- At least a number<br/>' +
          '- At least a special character<br/>' +
          '- Minimum 10 maximum 24 characters',
      },
      hero: {
        header: 'Manage your subscription online with Yettel account!',
        desc: (link: string | undefined) =>
          `Don't have an account yet? Register a new account with a few clicks. ${link ? 'If you are stuck, ask for help via <a href="' + link + '">chat</a>' : ''}.`,
      },
      result: {
        header: 'Yettel account registration',
        subHeader: 'The email has been sent',
        desc: 'We have sent an email with the activation link to the email address you provided. Please check your mailbox and finalize your registration! You can activate your Yettel account within 24 hours of registration, after that you will have to start the registration process again!',
        resend: 'Resend email',
        backToYettelAccount: 'Back to login',
      },
      registrationComplete: {
        header: 'Your account has been created',
        desc: 'Check your orders',
        goToLogin: 'continue to login',
      },
      registrationFailed: {
        header: 'Ups!<br />An unexpected error happened',
        desc: 'Something went wrong, contact us through video chat or try again later.',
        tryAgain: 'back to registration',
      },
    },
    registerWebView: {
      box: {
        header: 'Create Yettel account',
        title: 'Create a Yettel account! We will get in touch with you later via the given e-mail.',
        continue: 'Continue',
      },
    },
    otp: {
      header: 'Activation link',
      title: 'We have sent an email with the activation code. Please enter the code you received.',
      sendAgain: 'Send again',
      back: 'back',
      invalidCode: 'The entered activation code is not valid',
    },
  },
  password: {
    passwordHint: {
      title: '* The password must contain:',
      lowerCase: 'Lower case letters',
      upperCase: 'Upper case letters',
      atLeastANumber: 'At least a number',
      atLeastASpecial: 'At least a special character',
      lengthCheck: 'Minimum 10, maximum 24 characters',
    },
    errors: {
      lowerCase: 'The password does not contain lower case letters',
      upperCase: 'The password does not contain upper case letters',
      atLeastANumber: 'The password does not contain at least one number',
      atLeastASpecial: 'The password does not contain at least one special character',
      lengthCheck: 'The password is not long enough',
    },
  },
  emailChange: {
    header: 'Change email address',
    title: 'Enter your new email address.',
    email: 'Email address',
    emailAgain: 'Email address again',
    error: {
      invalidEmail: 'Invalid e-mail address',
      emailMismatch: 'The e-mail addresses do not match',
      general: 'Failed to change the e-mail address. Please try again later.',
    },
    continue: 'Continue',
    otpHeader: 'We have sent an e-mail with the activation code',
  },
  emailChangeBlock: {
    header: 'Are you sure you want to block the e-mail address change?',
    content1:
      'It seems that you are trying to change your e-mail address. If it was not you, block it and change your password as soon as you can!',
    block: 'Block e-mail change',
    cancel: 'Cancel',
    error: {
      header: 'Oops!<br />An unexpected error happened',
      subHeader: 'Failed to block the e-mail address change. Please try again later.',
      back: 'Back',
    },
  },
};
