import { MFACreateQrCodeResponse, UserDTO } from '@ytl/common-model';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { AppRoutes } from '../routes';
import { mfaResource } from '../shared/resource/mfa.resource';
import { MfaSMSPhoneNumberView } from '../shared/services/mfa/mfaSMSPhoneNumberView';
import { useMfaAuthenticatorQrCodeView } from '../shared/services/mfa/useMfaAuthenticatorQrCodeView';
import { useMfaAuthenticatorSuccessView } from '../shared/services/mfa/useMfaAuthenticatorSuccessView';
import { useMfaEnterAuthenticatorNameView } from '../shared/services/mfa/useMfaEnterAuthenticatorNameView';
import { useMfaSetupView } from '../shared/services/mfa/useMfaSetupView';
import { useMfaSMSSuccessView } from '../shared/services/mfa/useMfaSMSSuccessView';

export enum MFASetupStep {
  SelectType = 'selectType',
  EnterPhoneNumber = 'enterPhoneNumber',
  // EnterSMSCode = 'enterSMSCode',
  EnterAuthenticatorName = 'enterAuthenticatorName',
  ShowAuthenticatorQRCode = 'showAuthenticatorQRCode',
  SuccessSMS = 'successSMS',
  SuccessAuthenticator = 'successAuthenticator',
}

function calcStep(step: MFASetupStep): MFASetupStep {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('success') === 'authenticator') {
    return MFASetupStep.SuccessAuthenticator;
  } else if (searchParams.get('success') === 'sms') {
    return MFASetupStep.SuccessSMS;
  } else {
    return step;
  }
}

export const MfaSetupPage = () => {
  const [step, setStep] = useState<MFASetupStep>(MFASetupStep.SelectType);
  // const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState('');
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as UserDTO;
  const [qrCode, setQrCode] = useState<MFACreateQrCodeResponse>();

  const { view: selectTypeView } = useMfaSetupView((mode: 'sms' | 'authenticator') => {
    setStep(mode === 'sms' ? MFASetupStep.EnterPhoneNumber : MFASetupStep.EnterAuthenticatorName);
  });

  // const { view: enterPhoneNumberView } = MfaSMSPhoneNumberView(step, async (phoneNumber: string) => {
  //   await mfaResource.checkSmsNumber(phoneNumber);
  //   window.location.href = await mfaResource.getSmsConnectUrl({ phoneNumber });
  //   setSecondaryPhoneNumber(phoneNumber);
  // setStep(Steps.EnterSMSCode);
  // });

  const { view: enterAuthenticatorNameView } = useMfaEnterAuthenticatorNameView(async name => {
    const resp = await mfaResource.createQrCode(currentUser.email, name);
    setQrCode(resp.data);
    setStep(MFASetupStep.ShowAuthenticatorQRCode);
  }, () => setStep(MFASetupStep.SelectType));

  const { view: showAuthenticatorQrCodeView } = useMfaAuthenticatorQrCodeView(qrCode, async () => {
    // setStep(Steps.SuccessAuthenticator);
    if (qrCode?.encoded === undefined) throw new Error('qrCode.encoded is missing');
    window.location.href = await mfaResource.getTotpConnectUrl({ totpUrl: qrCode.encoded });
    // navigate(mfaResource.connectUrl(qrCode?.secret)));
  });

  const { view: showMfaAuthenticatorSuccessView } = useMfaAuthenticatorSuccessView(currentUser.email, () => {
    navigate(AppRoutes.homepage + '');
  });

  // const { view: enterSMSCodeView } = useMfaSMSCodeView(
  //   secondaryPhoneNumber.substring(secondaryPhoneNumber.length - 2),
  //   async (code: string) => {
  //     await mfaResource.validateSmsCode(code);
  //     setStep(Steps.SuccessSMS);
  //   },
  //   () => setStep(Steps.SelectType),
  // );

  const { view: successSMSView } = useMfaSMSSuccessView(currentUser.email, () => {
    navigate(AppRoutes.homepage);
  });

  const calculatedStep = calcStep(step);

  return (
    <div className={`mfa-setup-page mfa-setup-page--${calculatedStep}`}>
      {calculatedStep === MFASetupStep.SelectType && selectTypeView}
      {calculatedStep === MFASetupStep.EnterPhoneNumber && (
        <MfaSMSPhoneNumberView
          onContinue={async (phoneNumber: string) => {
            await mfaResource.checkSmsNumber(phoneNumber);
            window.location.href = await mfaResource.getSmsConnectUrl({ phoneNumber });
          }}
          onBack={() => setStep(MFASetupStep.SelectType)}
        />
      )}
      {/*{calculatedStep === Steps.EnterSMSCode && enterSMSCodeView}*/}
      {calculatedStep === MFASetupStep.SuccessSMS && successSMSView}

      {calculatedStep === MFASetupStep.EnterAuthenticatorName && enterAuthenticatorNameView}
      {calculatedStep === MFASetupStep.ShowAuthenticatorQRCode && showAuthenticatorQrCodeView}
      {calculatedStep === MFASetupStep.SuccessAuthenticator && showMfaAuthenticatorSuccessView}
    </div>
  );
};
